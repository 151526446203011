import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Layout } from 'antd';
import {
  UserAddOutlined,
  AreaChartOutlined,
  FormOutlined,
  BarChartOutlined,
  BellOutlined,
  UserSwitchOutlined,
  PartitionOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  PieChartOutlined,
  FileDoneOutlined,
  ClusterOutlined,
  ContactsOutlined,
} from '@ant-design/icons';

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  // Extract the pathname from location object
  const path = location.pathname;

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={toggleCollapsed}>
      <div className="logo" />
      <Menu theme="dark" selectedKeys={[path]} mode="inline" defaultOpenKeys={['reports', 'management']}>

        <SubMenu key="management" icon={<FileDoneOutlined />} title="Management">
        <Menu.Item key="/" icon={<UserAddOutlined />}>
          <Link to="/">Nominee Submission</Link>
        </Menu.Item>
          <Menu.Item key="/questions" icon={<FormOutlined />}>
            <Link to="/questions">Questions Editor</Link>
          </Menu.Item>
          <Menu.Item key="/reminder" icon={<BellOutlined />}>
            <Link to="/reminder">Email Reminders</Link>
          </Menu.Item>
          <Menu.Item key="/evaluatoredit" icon={<UserSwitchOutlined />}>
            <Link to="/evaluatoredit">Nominee Management</Link>
          </Menu.Item>
        </SubMenu>
        
        <SubMenu key="reports" icon={<AreaChartOutlined />} title="Reports">
          <Menu.Item key="/report" icon={<BarChartOutlined />}>
            <Link to="/report">Personalized Report</Link>
          </Menu.Item>
          <Menu.Item key="/companyreport" icon={<PieChartOutlined />}>
            <Link to="/companyreport">HGM Report</Link>
          </Menu.Item>
          <Menu.Item key="/detailed-report" icon={<ClusterOutlined />}>
            <Link to="/detailed-report">Detailed Report</Link>
          </Menu.Item>
          <Menu.Item key="/evaluationresponses" icon={<ContactsOutlined />}>
            <Link to="/evaluationresponses">Nominee Feedback</Link>
          </Menu.Item>
          <Menu.Item key="/reportcompletion" icon={<CheckCircleOutlined />}>
            <Link to="/reportcompletion">Survey Status Report</Link>
          </Menu.Item>
          <Menu.Item key="/yearreport" icon={<CalendarOutlined />}>
            <Link to="/yearreport">Strike Rate</Link>
          </Menu.Item>
          <Menu.Item key="/categoryreport" icon={<PartitionOutlined />}>
            <Link to="/categoryreport">CGM Report</Link>
          </Menu.Item>
        </SubMenu>

        
      </Menu>
    </Sider>
  );
};

export default Sidebar;
