const emailTemplate = `
Good Day!

Dear [Evauator],

Greetings from Colombo Leadership Academy!

[Company] implemented the Employer Value Proposition Project to map the Employer Brand of the company.

To understand the culture and to map the employee experience of the company, CLA is conducting a survey on the employees of [Company]. As respondents, you are requested to take a survey. This survey consists of [NumberOfQuestions] questions and you will be rating your experience on a scale of 1 to 5 on each of the statements. And you will also be required to provide qualitative feedback. The survey will require 10 – 15 minutes of your time.
		
Your individual responses are confidential. 

Please use the below link to take the assessment. The survey will be open from [FromDate] to [ToDate].

[URL]

Please reach out to survey@clacoaching.com / 072-0865001 (Nilakshi) for any clarifications.

Best Regards,
Colombo Leadership Academy

`;

export default emailTemplate;
