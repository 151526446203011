import React, { useState } from 'react';
import { Table, Select, Spin, Divider, Typography, Button } from 'antd';
import axios from 'axios';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

const { Title } = Typography;
const { Option } = Select;

const years = [];
const currentYear = new Date().getFullYear();
years.push(currentYear + 1);
for (let i = 0; i < 10; i++) {
  years.push(currentYear - i);
}

const CategoryReport = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([
    {
      title: 'Company Name',
      dataIndex: 'CompanyName',
      key: 'CompanyName',
    },
    {
      title: 'DACTI - Average Scores',
      dataIndex: 'DACTI - Average Scores',
      key: 'DACTI - Average Scores',
    },
  ]);

  const fetchReport = async (year) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://api-back.greatmanagers.org/categoryavg/${year}`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            // Add any other headers here if needed
        }
    });
      const fetchedData = response.data;

      if (fetchedData.length > 0) {
        // Dynamically create columns based on response data keys
        const dynamicColumns = Object.keys(fetchedData[0])
          .filter(key => key !== 'CompanyName' && key !== 'DACTI - Average Scores')
          .map(key => ({
            title: key.replace('_Score', ' Score'),
            dataIndex: key,
            key,
          }));

        setColumns([
          {
            title: 'Company Name',
            dataIndex: 'CompanyName',
            key: 'CompanyName',
          },
          ...dynamicColumns,
          {
            title: 'DACTI - Average Scores',
            dataIndex: 'DACTI - Average Scores',
            key: 'DACTI - Average Scores',
          },
        ]);
      }

      setData(fetchedData);
    } catch (error) {
      console.error('Error fetching category report:', error);
    } finally {
      setLoading(false);
    }
  };

  const exportToCSV = () => {
    if (data.length === 0) {
      return;
    }

    // Prepare CSV content using PapaParse
    const csv = Papa.unparse(data, {
      header: true,
      delimiter: ',',
      quotes: false,
      encoding: 'utf-8',
    });

    // Create Blob and save the file using FileSaver
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `category_report_${new Date().toISOString()}.csv`);
  };

  return (
    <div style={{ backgroundColor: '#F6F7FB', padding: '20px' }}>
      <Title>CGM Report</Title>
      <Title level={5} style={{ marginTop: '-20px', marginBottom: '30px' }}>
      Analyze CGM Data And Insights
            </Title>
      <div style={{
                marginBottom: '20px',
                backgroundColor: '#FFFFFF',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                borderRadius: '10px',
                padding: '10px 30px',
                paddingBottom: '30px'
            }} >
        <Title level={5}>Select a Year</Title>
        <Select
          placeholder="Select Year"
          style={{ 
                                            backgroundColor: '#F6F7FB',
                                            borderRadius: '10px',
                                            border: '1px solid #D9D9D9',
                                            minHeight: '32px',
                                            fontWeight: 'bold',
                                            width: '100%'
                                            }}
          onChange={fetchReport}
          
        >
          {years.map(year => (
            <Option key={year} value={year}>{year}</Option>
          ))}
        </Select>
      </div>
      <Spin spinning={loading}>
      <div style={{
                marginBottom: '20px',
                backgroundColor: '#FFFFFF',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                borderRadius: '10px',
                padding: '10px 30px',
                paddingBottom: '30px'
            }}>
          <Button type="primary" onClick={exportToCSV} style={{ marginBottom: 20, float: 'right' , marginTop: '20px',backgroundColor:'#3B7DDE'}}>
            Export as CSV
          </Button>

        <Table
          columns={columns}
          dataSource={data}
          rowKey="CompanyName"
          pagination={false}
          components={{
            header: {
              cell: (props) => (
                <th {...props} style={{ backgroundColor: '#F6F7FB' }} />
              ),
            },
          }}
        />
        </div>
      </Spin>
    </div>
  );
};

export default CategoryReport;
