import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select, Table, Spin, Typography, Button,Divider,Row,Col } from 'antd';
import { saveAs } from 'file-saver';

const { Option } = Select;
const { Title, Text } = Typography;

const CompanyReport = () => {
    const [companies, setCompanies] = useState([]);
    const [selectedCompanyName, setSelectedCompanyName] = useState('');
    const [selectedCompanyYear, setSelectedCompanyYear] = useState('');
    const [selectedCompanyId, setSelectedCompanyId] = useState('');
    const [companyReport, setCompanyReport] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeReport, setActiveReport] = useState('');

    useEffect(() => {
        fetchCompanies();
    }, []);

    const fetchCompanies = async () => {
        try {
            const response = await axios.get('https://api-back.greatmanagers.org/companiesi', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    };

    const fetchCompanyReport = async (companyId) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://api-back.greatmanagers.org/company-report/${companyId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            setCompanyReport(response.data);
            setActiveReport('company');
        } catch (error) {
            console.error('Error fetching company report:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCompanyNameChange = (value) => {
        setSelectedCompanyName(value);
        setSelectedCompanyYear('');
        setSelectedCompanyId('');
        setCompanyReport([]);
        setActiveReport('');
    };

    const handleCompanyYearChange = (value) => {
        setSelectedCompanyYear(value);
        const selectedCompany = companies.find(company =>
            company.CompanyName === selectedCompanyName && company.CompanyYear === parseInt(value)
        );
        setSelectedCompanyId(selectedCompany ? selectedCompany.CompanyID : '');
        if (selectedCompany) {
            fetchCompanyReport(selectedCompany.CompanyID);
        }
    };

    const companyReportColumns = [
        { title: 'Company Name', dataIndex: 'CompanyName', key: 'CompanyName' },
        { title: 'Full Name', dataIndex: 'FullName', key: 'FullName' },
        { title: 'Designation', dataIndex: 'Designation', key: 'Designation' },
        { title: 'People Who Evaluated', dataIndex: 'PeopleWhoEvaluated', key: 'PeopleWhoEvaluated' },
        ...Object.keys(companyReport[0] || {}).filter(key =>
            !['CompanyName', 'FullName', 'Designation', 'PeopleWhoEvaluated', 'Total'].includes(key)
        ).map(key => ({ title: key, dataIndex: key, key })),
        { title: 'Average', dataIndex: 'Total', key: 'Total' },
    ];

    const preprocessDataForCSV = (data) => {
        return data;
    };

    const exportToCSV = (data, filename) => {
        if (data.length === 0) return;
        const headers = Object.keys(data[0]).join(',');
        const csvContent = data.map(row => Object.values(row).map(value => `"${value}"`).join(',')).join('\n');
        const blob = new Blob([`${headers}\n${csvContent}`], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `${filename}.csv`);
    };

    return (
        <div style={{ backgroundColor: '#F6F7FB', padding: '20px' }}>
            <Title>HGM Report</Title>
            <Title level={5} style={{ marginTop: '-20px', marginBottom: '30px' }}>
                Analyze Company Data And Insights
            </Title>
            <div style={{
                marginBottom: '20px',
                backgroundColor: '#FFFFFF',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                borderRadius: '10px',
                padding: '10px 30px',
                paddingBottom: '30px'
            }}>
            <Row gutter={16}>
                                <Col span={12}>
                                <Title level={5}>Company Name:</Title>
                                </Col>
                                <Col span={12}>
                                <Title level={5}>Year:</Title>
                                </Col>
                                </Row>
                <Row gutter={[24]}>
                    <Col span={12}>
                        <Select
                            placeholder="Select a company"
                            onChange={handleCompanyNameChange}
                            value={selectedCompanyName}
                            style={{ 
                                            backgroundColor: '#F6F7FB',
                                            borderRadius: '10px',
                                            border: '1px solid #D9D9D9',
                                            minHeight: '32px',
                                            fontWeight: 'bold',
                                            width: '100%'
                                            }}

                        >
                            {Array.from(new Set(companies.map(company => company.CompanyName))).map(name => (
                                <Option key={name} value={name}>{name}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={12}>
                        {selectedCompanyName && (
                            <Select
                                placeholder="Select a company year"
                                onChange={handleCompanyYearChange}
                                value={selectedCompanyYear}
                                style={{ 
                                            backgroundColor: '#F6F7FB',
                                            borderRadius: '10px',
                                            border: '1px solid #D9D9D9',
                                            minHeight: '32px',
                                            fontWeight: 'bold',
                                            width: '100%'
                                            }}
                            >
                                {Array.from(new Set(companies
                                    .filter(company => company.CompanyName === selectedCompanyName)
                                    .map(company => company.CompanyYear)))
                                    .map(year => (
                                        <Option key={year} value={year}>{year}</Option>
                                    ))}
                            </Select>
                        )}
                    </Col>
                </Row>
            </div>
            {loading ? (
                <Spin style={{ marginTop: '20px' }} />
            ) : (
                activeReport === 'company' && companyReport.length > 0 && (
                    <div style={{
                marginBottom: '20px',
                backgroundColor: '#FFFFFF',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                borderRadius: '10px',
                padding: '10px 30px',
                paddingBottom: '30px'
            }}>
                <Button
                            type="primary"
                            style={{ marginTop: '20px', float: 'right', marginBottom: '20px',backgroundColor:'#3B7DDE' }}
                            onClick={() => exportToCSV(preprocessDataForCSV(companyReport), 'Company Report')}
                        >
                            Export as CSV
                            </Button>
                        <Table
                            columns={companyReportColumns}
                            dataSource={companyReport}
                            rowKey={(record, index) => index}
                            pagination={false}
                            components={{
            header: {
              cell: (props) => (
                <th {...props} style={{ backgroundColor: '#F6F7FB' }} />
              ),
            },
          }}
                        />
                        
                        
                    </div>
                )
            )}
        </div>
    );
};
export default CompanyReport;